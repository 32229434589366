import FlatCategorySVG from 'public/assets/flat-category.svg';
import FlatProvidersSVG from 'public/assets/flat-providers.svg';
import FlatSortSVG from 'public/assets/flat-sort.svg';

export const EXCLUDED_CATEGORIES = [
  'accumulating',
  'booming-tournament',
  'fresh-releases',
  '_hd',
  'not_slots',
  'top-games',
  'vip-games',
];

export const CATEGORY_MAPPING: Record<string, string> = {
  popular: 'top-games',
  new: 'fresh-releases',
  'bonus-buy-in': 'feature-buy',
};

export const LANDING_COLLECTIONS = [
  'skill-games',
  'slots',
  'top-games',
  'live-casino',
  'fresh-releases',
  'bonus-buy',
  'table-games',
];

export const FILTER_STEPS = [
  {
    id: 'providers',
    value: 'providers',
    label: 'Providers',
    leadingIcon: (
      <FlatProvidersSVG className="h-5 w-5 fill-current fill-text-default" />
    ),
  },
  {
    id: 'collection',
    value: 'collection',
    label: 'Collection',
    leadingIcon: (
      <FlatCategorySVG className="h-5 w-5 fill-current fill-text-default" />
    ),
  },
  {
    id: 'sort-by',
    value: 'sort-by',
    label: 'Sort By',
    leadingIcon: (
      <FlatSortSVG className="h-5 w-5 fill-current fill-text-default" />
    ),
  },
];
