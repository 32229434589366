import React from 'react';
import { Transition } from '@headlessui/react';
import FavoriteButton from '@/components/FavoriteButton';
import useIsRestricted from '@/hooks/games/use-is-restricted';
import RestrictedGamecardOverlay from './restricted-gamecard-overlay';
import { isJackpot } from '../Games/utils';

interface Props {
  title?: string;
  playIcon: React.ReactNode;
  show: boolean;
  hideFavoriteButton: boolean;
  isFavorited?: boolean;
  onFavoriteClick?: (
    evt: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void;
  provider: string;
  isJackpot?: boolean;
  isDisabled?: boolean;
}

const GameCardOverlay = ({
  show,
  title,
  playIcon,
  hideFavoriteButton,
  onFavoriteClick,
  isFavorited,
  provider,
  isJackpot,
  isDisabled,
}: Props) => {
  const { isRestricted, isPending } = useIsRestricted(provider);

  const isRestrictedUpdated = isRestricted || isJackpot;

  return (
    <Transition
      show={show}
      appear={true}
      enter={`transition duration-200 ease-linear`}
      enterFrom={`opacity-0`}
      enterTo={`opacity-100`}
      leave={`transition duration-200 ease-out`}
      leaveFrom={`opacity-100`}
      leaveTo={`opacity-0`}
      className="absolute
          inset-0
          z-50 flex
          items-center 
          justify-center border-2
          border-gray-800/90
          bg-gray-800/90
          "
    >
      {isRestrictedUpdated ? (
        <RestrictedGamecardOverlay />
      ) : (
        <>
          {!hideFavoriteButton && (
            <FavoriteButton
              isFavorited={isFavorited}
              onFavoriteClick={onFavoriteClick}
              isDisabled={isDisabled}
            />
          )}
          <div className="relative flex flex-col items-center justify-center px-4 text-text-on-primary">
            <span className="mb-spacing-2xs text-center text-base font-medium leading-4">
              {title}
            </span>
            <span className="mb-1.5 text-xs capitalize text-text-subdued">
              {provider}
            </span>
            <span className="h-10 w-10 rounded-full bg-bgr-hovered [&>svg]:fill-current [&>svg]:text-base-primary">
              {playIcon}
            </span>
          </div>
        </>
      )}
    </Transition>
  );
};

export default GameCardOverlay;
