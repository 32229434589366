import React, { useCallback } from 'react';
import { GameCard } from '@/components/GameCard';
import Link from 'next/link';
import { useAuth } from '@/hooks/auth';
import useShouldFillProfile from '@/hooks/use-should-fill-profile';
import useModal from '@/hooks/modal';
import PlaySVG from 'public/assets/play-new.svg';
import {
  useAddToFavorites,
  useFavoriteGamesData,
  useRemoveFromFavorites,
} from '@/hooks/games/favorites';
import useIsRestricted from '@/hooks/games/use-is-restricted';
import { cn } from '@/utils/style';
import { FALLBACK_SRC_BASE } from '../GameCard/constants';

const GameCarouselItem = React.memo(({ game, isInMiniCarousel }: any) => {
  const shouldFillProfile = useShouldFillProfile(game?.provider);
  const { mutateAsync: addToFavorites, isPending: isAddingToFavorites } =
    useAddToFavorites();
  const {
    mutateAsync: removeFromFavorites,
    isPending: isRemovingFromFavorites,
  } = useRemoveFromFavorites();
  const { favoriteGameIdentifiers, completeGames, favoriteIdsAndIdentifiers } =
    useFavoriteGamesData();

  const { isRestricted, isPending } = useIsRestricted(game?.provider);

  const { open } = useModal();
  const {
    user: { isAuthenticated },
  } = useAuth();
  const isMutating = isAddingToFavorites || isRemovingFromFavorites;

  const handleOnFavoriteClick = useCallback(() => {
    if (!favoriteGameIdentifiers?.includes(game.identifier)) {
      addToFavorites({
        gameID: game.id,
        completeGame: game,
      });
    } else {
      removeFromFavorites({
        gameID: game.id,
        completeGame: game,
      });
    }
  }, [addToFavorites, favoriteGameIdentifiers, game, removeFromFavorites]);

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (typeof window !== 'undefined') {
      const bottomDrawerElement = document.getElementById(
        'game-page-bottom-drawer',
      );
      if (bottomDrawerElement) {
        bottomDrawerElement.style.display = 'none';
      }

      const closeEvent = new CustomEvent('closeBottomDrawer');
      document.dispatchEvent(closeEvent);
    }

    if (shouldFillProfile) {
      e.preventDefault();
      open('fill-profile');
      return;
    }
    if (isRestricted) {
      e.preventDefault();
    }
  };

  return (
    <div key={game?.slug}>
      <Link
        href={`/games/${game?.slug}`}
        className={cn(['relative block', isRestricted && 'cursor-default'])}
        onClick={handleClick}
      >
        <GameCard
          isInMiniCarousel={isInMiniCarousel}
          provider={game?.provider}
          isFavorited={favoriteGameIdentifiers?.includes(game.identifier)}
          hideFavoriteButton={!isAuthenticated}
          onFavoriteClick={handleOnFavoriteClick}
          isDisabled={isMutating}
          carouselDisplayed
          imageUrl={`https://cdn.wild.io/thumbnail/${game?.identifier}.png`}
          fallBackUrl={`${FALLBACK_SRC_BASE}/thumbnail/${
            game?.identifier.split('/')[0]
          }/${game?.identifier.split('/')[1]}.webp`}
          title={game.title}
          playIcon={<PlaySVG />}
        />
      </Link>
    </div>
  );
});

GameCarouselItem.displayName = 'GameCarouselItem';

export default GameCarouselItem;
