import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { QueryClient } from '@tanstack/react-query';
import { useAuth } from '@/hooks/auth';
import { isMobile } from 'mobile-device-detect';
import {
  GameFilterPayload,
  GameFilterSelectPayload,
  GameResult,
} from '@/types/games_filters';
import client from '@/utils/api';
import { normalizeFilteredGames } from './utils';
import { getGameCarouselFilters } from '@/modules/category-page/utils';
import { captureException } from '@sentry/nextjs';

export const fetchGamesWithFilters = async (
  payload: GameFilterPayload,
  authCurrency: string = 'BTC',
) => {
  const platform = isMobile ? 'mobile' : 'desktop';
  try {
    const res = await client(
      'games_filter',
      { ...payload, device: platform as 'mobile' | 'desktop' | undefined },
      'POST',
      {
        Accept: 'application/vnd.s.v2+json',
      },
    );
    return {
      ...res,
      data: normalizeFilteredGames(res.data, authCurrency),
    };
  } catch (e) {
    console.log(e);
    captureException(e);
  }
};

export const fetchGamesById = async (
  payload: GameFilterSelectPayload,
  authCurrency?: string,
) => {
  const platform = isMobile ? 'mobile' : 'desktop';
  try {
    const res = await client(
      'games_filter/select',
      { ...payload, device: platform as 'mobile' | 'desktop' | undefined },
      'POST',
      {
        Accept: 'application/vnd.s.v2+json',
      },
    );
    const resArr = Object.keys(res).map((key) => res[key]);
    return normalizeFilteredGames(resArr, authCurrency);
  } catch (e) {
    console.log(e);
    captureException(e);
  }
};

export const useFilteredGame = (
  title: string,
  options = {} as GameFilterPayload,
): UseQueryResult<{
  data: GameResult['data'];
  pagination: GameResult['pagination'];
}> => {
  const platform = isMobile ? 'mobile' : 'desktop';

  return useQuery({
    queryKey: [
      'filteredGame',
      {
        page: 1,
        page_size: 1,
        filter: {
          title,
        },
        device: platform as 'mobile' | 'desktop' | undefined,
        without_territorial_restrictions:
          options?.without_territorial_restrictions ?? true,
      },
    ],
    queryFn: ({ queryKey }) => {
      return fetchGamesWithFilters(queryKey[1] as any);
    },
    ...options,
  });
};

// needs refactor after updating the fetchGamesById function
export const useGamesByIds = (
  filters: GameFilterSelectPayload = {} as GameFilterSelectPayload,
  options = {},
): UseQueryResult<any> => {
  const { user } = useAuth();
  const platform = isMobile ? 'mobile' : 'desktop';
  return useQuery({
    queryKey: ['filteredGamesByIds', { ...filters, device: platform }],
    queryFn: ({ queryKey }: any) => {
      return fetchGamesById(
        queryKey[1],
        user?.currentUser?.currency?.toUpperCase(),
      );
    },
    ...options,
  });
};

// @/utils/content-api.ts

interface PrefetchInfiniteFilteredGamesParams {
  queryClient: QueryClient;
  isUserAgentMobile: boolean;
  filterType: string;
}

export const prefetchInfiniteFilteredGames = async ({
  queryClient,
  isUserAgentMobile,
  filterType,
}: PrefetchInfiniteFilteredGamesParams) => {
  return queryClient.prefetchInfiniteQuery({
    initialPageParam: 0 as any,
    queryKey: [
      'infiniteFilteredGames',
      getGameCarouselFilters(isUserAgentMobile, filterType),
    ],
    queryFn: ({ queryKey, pageParam }) => {
      const params = queryKey[1] || {};
      return fetchGamesWithFilters({
        ...params,
        ...pageParam,
      }).then((res) => res);
    },
  });
};
