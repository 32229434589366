import Image from 'next/image';
import React, { useRef, useState, useEffect } from 'react';
import { GameCardLoader } from '../Loaders';
import useIntersectionObserver from '@/hooks/use-intersection-observer';
import { cn } from '@/utils/style';

const BlurryImage = ({
  src,
  setSrc,
  fallBackUrl,
  alt,
}: {
  src: string | null | undefined;
  setSrc: (src: string) => void;
  fallBackUrl?: string;
  alt: string;
}) => {
  const [hasBeenVisible, setHasBeenVisible] = useState(false);
  const [isClient, setIsClient] = useState(false);
  const imageRef = useRef(null);

  useEffect(() => {
    setIsClient(true);
  }, []);

  const onIntersect = (entry: IntersectionObserverEntry) => {
    if (entry.isIntersecting && !hasBeenVisible) {
      setHasBeenVisible(true);
    }
  };

  useIntersectionObserver({
    target: imageRef,
    onIntersect,
    threshold: 0,
    rootMargin: '50px',
    root: null,
    freezeOnceVisible: true,
  });

  return (
    <div ref={imageRef} className="relative h-full w-full">
      <Image
        className={cn([
          'object-cover -indent-100 transition-[filter] duration-500 ease-in-out',
          isClient && !hasBeenVisible && 'blur-3xl',
        ])}
        quality={isClient ? (hasBeenVisible ? 100 : 1) : 100}
        fill
        src={src as string}
        onError={() => setSrc(fallBackUrl ?? '')}
        alt={alt}
        sizes="(max-width: 768px) 50vw, (max-width: 1200px) 33vw, 15vw"
        priority
      />
      <GameCardLoader className="absolute inset-0" />
    </div>
  );
};

export default BlurryImage;
