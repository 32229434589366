'use client';
import { useTheme } from 'next-themes';
import React, { useEffect, useMemo, useState } from 'react';

const useDarkMode = () => {
  const [mounted, setMounted] = useState(false);
  const { setTheme, theme, forcedTheme } = useTheme();

  useEffect(() => {
    setMounted(true);
  }, []);

  const isDark = useMemo(() => {
    return forcedTheme === 'dark';
  }, [forcedTheme]);

  return {
    mounted,
    setDarkMode: setTheme,
    darkMode: isDark,
  };
};

export default useDarkMode;
