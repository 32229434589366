import { useMemo } from 'react';
import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import { useAuth } from '../auth';
import client from '@/utils/api';
import { useGamesByIds } from './filters';
import { useToast } from '@purposeinplay/utils';
import { useTranslation } from '@/app/i18n/client';
import { useOtherToastMessages } from '@/hooks/use-other-toast-messages';
import { useAnalytics } from '@/hooks/use-analytics';

type AddToFavoritesVariables = {
  gameID: number;
  completeGame: any;
};

type RemoveFromFavoritesVariables = {
  gameID: number;
  completeGame: any;
};

export interface FavoriteGameInfo {
  title: string;
  uniq_seo_title: boolean;
  lines: number;
  ways: number | null;
  volatility_rating: 'low' | 'medium' | 'high' | 'very high';
  hit_rate: number | null;
  payout: string;
  devices: string[];
  provider: string;
  identifier: string;
  seo_title: string;
  currencies: {
    [currency: string]: {
      id: number;
      jackpot: number | null;
    };
  };
  categories: string[];
  unfinished_games_for: string[];
}

export const useAddToFavorites = (): UseMutationResult<
  any,
  unknown,
  AddToFavoritesVariables
> => {
  const toastMessages = useOtherToastMessages();
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const { track } = useAnalytics();

  return useMutation({
    mutationKey: ['addToFavorites'],
    mutationFn: ({ gameID, completeGame }: AddToFavoritesVariables) =>
      client(`player/favorite_games/${gameID}`, {}, 'PUT').then((res) => {
        track('game_favorite_added', {
          category: 'user',
          label: gameID,
        });
        return res;
      }),
    retry: false,
    onMutate: async ({ gameID, completeGame }: AddToFavoritesVariables) => {
      await queryClient.cancelQueries({ queryKey: ['favoriteGames'] });
      const prevFavoriteData = queryClient.getQueryData(['favoriteGames']);
      queryClient.setQueryData(['favoriteGames'], (old: any) => {
        return [...old, completeGame];
      });
      return { prevFavoriteData };
    },
    onError: (err, variables, context: any) => {
      queryClient.setQueryData(['favoriteGames'], context.prevFavoriteData);
      toast({
        state: 'error',
        title: toastMessages?.favorites_auth_error,
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['favoriteGames'] });
      toast({
        state: 'success',
        title: toastMessages?.favorites_success,
      });
    },
  });
};

export const useRemoveFromFavorites = (): UseMutationResult<
  any,
  unknown,
  RemoveFromFavoritesVariables
> => {
  const { t } = useTranslation();
  const toastMessages = useOtherToastMessages();
  const { toast } = useToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['removeFromFavorites'],
    mutationFn: ({ gameID, completeGame }: any) =>
      client(`player/favorite_games/${gameID}`, {}, 'DELETE'),
    retry: false,
    onMutate: async ({
      gameID,
      completeGame,
    }: RemoveFromFavoritesVariables) => {
      await queryClient.cancelQueries({ queryKey: ['favoriteGames'] });
      const prevFavoriteData = queryClient.getQueryData(['favoriteGames']);
      queryClient.setQueryData(['favoriteGames'], (old: any) => {
        return old.filter(
          (t: any) => t.identifier !== completeGame?.identifier,
        );
      });
      return { prevFavoriteData };
    },
    onError: (context) => {
      //@ts-ignore
      queryClient.setQueryData(['favoriteGames'], context.prevFavoriteData);
      toast({
        state: 'error',
        title: toastMessages?.favorites_general_error,
      });
    },
    onSettled: (data: any) => {
      queryClient.invalidateQueries({
        queryKey: ['favoriteGames', data?.gameId],
      });
      toast({
        state: 'success',
        title: toastMessages?.favorites_removed,
      });
    },
  });
};

export const useFavoriteGames = (): UseQueryResult<FavoriteGameInfo[]> => {
  const {
    user: { currentUser, isLoading: userLoading },
  }: any = useAuth();
  return useQuery({
    queryKey: ['favoriteGames'],
    queryFn: async () => {
      try {
        return await client(`player/favorite_games`, { isV2: true });
      } catch (err: any) {
        if (err && err.code === 401) {
          return [];
        }
      }
    },
    enabled: !userLoading && currentUser?.email ? true : false,
  });
};

export const useFavoriteGamesData = () => {
  const {
    data: favoriteGames = [],
    isLoading,
    isPending: favoritesPending,
  } = useFavoriteGames();

  const favoriteGameIdentifiers = useMemo(() => {
    return favoriteGames?.length > 0
      ? favoriteGames?.map((game: any) => game?.identifier)
      : [];
  }, [favoriteGames]);

  const { data: completeGames } = useGamesByIds(
    {
      game_ids: [...favoriteGameIdentifiers],
    },
    {
      enabled: favoriteGameIdentifiers?.length > 0,
    },
  );

  const favoriteIdsAndIdentifiers = useMemo(() => {
    return completeGames?.map((game: any) => {
      return {
        id: game.id,
        identifier: game.identifier,
        slug: game.slug,
      };
    });
  }, [completeGames]);

  return {
    favoriteIdsAndIdentifiers,
    favoriteGameIdentifiers,
    favoritesLoading: isLoading,
    favoritesPending,
    completeGamesPending: favoritesPending,
    completeGames: favoriteGames,
    completeGamesLoading: isLoading,
  };
};

export type IdsAndIdentifiersProps = {
  id: string;
  identifier: string;
};
