'use client';
import { GameFilterPayload, GameResult } from '@/types/games_filters';
import {
  keepPreviousData,
  useInfiniteQuery,
  UseInfiniteQueryResult,
} from '@tanstack/react-query';
import { fetchGamesWithFilters } from './filters';
import { useAuth } from '../auth';

export const useGamesWithFilters = (
  filters: GameFilterPayload = {} as GameFilterPayload,
  options = {},
): UseInfiniteQueryResult<{
  pageParams: any;
  pages: {
    data: GameResult['data'];
    pagination: GameResult['pagination'];
  }[];
}> => {
  const { user } = useAuth();
  const { page, ...rest } = filters;

  return useInfiniteQuery({
    initialPageParam: 0 as any,
    queryKey: ['infiniteFilteredGames', rest],
    queryFn: ({ queryKey, pageParam }) => {
      const params = queryKey[1] || ({} as any);
      return fetchGamesWithFilters(
        {
          ...params,
          page: pageParam?.page || page || 1,
          ...pageParam,
        },
        user?.currentUser?.currency
          ? user?.currentUser?.currency?.toUpperCase()
          : undefined,
      ).then((res) => res);
    },
    placeholderData: keepPreviousData,
    getPreviousPageParam: (firstPage: any, allPages) => {
      if (!firstPage?.pagination?.prev_page) return undefined;
      return {
        ...filters,
        page: firstPage?.pagination?.prev_page,
      };
    },
    getNextPageParam: (lastPage: any, allPages) => {
      if (!lastPage?.pagination?.next_page) return undefined;
      return {
        ...filters,
        page: lastPage?.pagination?.next_page,
      };
    },
    refetchOnMount: false,
    ...options,
  });
};
