import { cn } from '@/utils/style';
import { Heading } from '@purposeinplay/core-v2';
import Link from 'next/link';
import React, { useMemo } from 'react';

const Section: React.FC<{
  children?: React.ReactNode;
  title?: string;
  titleStyles?: string;
  href?: string;
  icon?: React.ReactNode;
  className?: string;
  trailingElement?: React.ReactNode;
}> = ({
  title,
  titleStyles,
  href,
  icon,
  trailingElement,
  className,
  children,
}) => {
  const sectionHeading = useMemo(() => {
    return (
      <div className="flex flex-row items-center space-x-spacing-xs truncate">
        {icon && (
          <div className="relative flex h-7 w-7 items-center justify-center">
            {icon}
          </div>
        )}
        <Heading
          as="h2"
          className="max-w-[12rem] truncate  text-xl font-semibold sm:max-w-none"
        >
          {title}
        </Heading>
      </div>
    );
  }, [title, icon]);
  return (
    <div className={cn(['mb-7 md:mb-8', className])}>
      {title && (
        <div
          className={cn([
            'mb-3 flex flex-row items-center justify-between',
            titleStyles,
          ])}
        >
          {href ? (
            <Link href={href} passHref prefetch={false}>
              {sectionHeading}
            </Link>
          ) : (
            sectionHeading
          )}
          {trailingElement}
        </div>
      )}
      {children}
    </div>
  );
};

export default Section;
