// @ts-ignore
import getSlug from 'speakingurl';
import { FilteredGame } from '@/types/games_filters';

export const normalizeGames = (games: any) => {
  return Object.entries(games).map((game) => {
    const [identifier, props]: any = game;
    const slug = `${getSlug(props.title)}-${getSlug(props.provider)}`;
    const newProps = { ...props, slug };
    newProps.currencies =
      (props?.real &&
        Object.keys(props.real).reduce((acc: any, currency) => {
          acc[currency] = props.real[currency].id;
          return acc;
        }, {})) ||
      {};
    if (props.demo) {
      const id = parseInt(props.demo.split('/').pop());
      newProps.currencies['FUN'] = id;
      newProps['id'] = id;
    }
    delete newProps.real;
    delete newProps.demo;
    return {
      identifier,
      ...newProps,
    };
  });
};

export const normalizeFilteredGames = (
  games: FilteredGame[],
  authCurrency?: any,
) => {
  return games.map((game) => {
    const { identifier, title, provider } = game;
    const slug = game?.seo_title;

    const newProps = { ...game, slug };

    newProps['id'] = game?.currencies['FUN']
      ? game?.currencies['FUN']?.id
      : authCurrency && game?.currencies[authCurrency]
        ? game?.currencies[authCurrency]?.id
        : game?.currencies[Object.keys(game?.currencies)[0]]?.id;
    newProps['currencies'] =
      Object.keys(game?.currencies).reduce((acc: any, currency) => {
        acc[currency] = game?.currencies[currency].id;
        return acc;
      }, {}) || {};

    return {
      // @ts-ignore
      identifier,
      ...newProps,
    };
  });
};

export function extractGameAndProvider(str: string): {
  game: string;
  provider: string;
} {
  const parts = str.split('-');

  const providerPart = parts.pop() || 'Unknown';

  const game = parts
    .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
    .join(' ');

  const provider = providerPart.charAt(0).toUpperCase() + providerPart.slice(1);

  return {
    game,
    provider,
  };
}

export const getGameTitleBySlug = (slug: string) => {
  // Split the slug into words by "-"
  const words = slug.split('-');

  // Remove the last part of the slug, as we don't need it
  words.pop();

  return words.join('-');
};
