import useMarks from '../marks';

const useIsRestricted = (gameProvider: string) => {
  const { data: restrictions, isPending } = useMarks();

  if (!gameProvider) {
    return { isRestricted: false, isPending };
  }

  const isRestricted =
    restrictions?.some((mark) => {
      if (gameProvider.includes('pragmatic')) {
        return mark.includes('pragmatic');
      }
      return mark.includes(gameProvider);
    }) || false;

  return { isRestricted, isPending };
};

export default useIsRestricted;
