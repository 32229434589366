import React from 'react';
import { GameCardLoader } from '../Loaders';
import BlurryImage from './blurry-image';
import Image from 'next/image';

interface Props {
  src: string | null | undefined;
  isPending: boolean;
  setSrc: (src: string) => void;
  onError: () => void;
  imageUrl?: string;
  image?: React.ReactNode;
  title?: string;
  fallBackUrl?: string;
}

const GameCardImage = ({
  src,
  isPending,
  setSrc,
  imageUrl,
  image,
  title,
  fallBackUrl,
}: Props) => {
  if (!src) return <GameCardLoader />;

  if (image) return image;

  return (
    <BlurryImage
      src={src}
      setSrc={setSrc}
      fallBackUrl={fallBackUrl}
      alt={`Game card image for ${title || 'game'}`}
    />
  );
};

export default GameCardImage;
