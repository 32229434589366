import { useQuery } from '@tanstack/react-query';
import { Collection } from '@/types/games';
import client from '../utils/api';
import config from '@/config';

const useWildCategories = () => {
  return useQuery({
    queryKey: ['wild-categories'],
    queryFn: () =>
      client('games/collections').then((data = []) => {
        return data?.filter((collection: any) =>
          config.SHOWN_WILD_CATEGORIES.includes(collection.id),
        );
      }),
  });
};

export const REMOVED_CATEGORIES = ['_hd', 'not_slots'];

export const useAllWildCategories = () => {
  return useQuery({
    queryKey: ['all-wild-categories'],
    queryFn: () =>
      client('games/collections').then((data = []) => {
        return data?.filter(
          (collection: any) => !REMOVED_CATEGORIES.includes(collection.id),
        );
      }),
  });
};

export const useWildCollections = () => {
  return useQuery({
    queryKey: ['wild-collections'],
    queryFn: () =>
      client('games/collections').then((data = []) => {
        const filtered = data.filter((collection: any) =>
          config.SHOWN_WILD_COLLECTIONS.includes(collection.id),
        );
        const ordered = filtered.sort(
          (a: any, b: any) =>
            config.SHOWN_WILD_COLLECTIONS.indexOf(a.id) -
            config.SHOWN_WILD_COLLECTIONS.indexOf(b.id),
        );
        return ordered;
      }),
  });
};

const useCollection = (collectionId: string) => {
  const { data, isLoading } = useWildCategories();

  return {
    isLoading,
    collection: data?.find(
      (collection: Collection) => collection.id === collectionId,
    ),
  };
};

export { useWildCategories, useCollection };
