'use client';
import React, { createContext, useContext, ReactNode, useMemo } from 'react';
import { isMobile as defaultIsMobile } from 'mobile-device-detect';

interface IsMobileContextProps {
  isUserAgentMobile: boolean;
}

const IsMobileContext = createContext<IsMobileContextProps | undefined>(
  undefined,
);

export const useIsMobile = () => {
  const context = useContext(IsMobileContext);
  if (!context) {
    console.warn(
      'useIsMobile was used outside of IsMobileProvider. Falling back to default.',
    );
    return defaultIsMobile;
  }
  return context.isUserAgentMobile;
};

interface IsMobileProviderProps {
  isUserAgentMobile?: boolean;
  children: ReactNode;
}

export const IsMobileProvider: React.FC<IsMobileProviderProps> = ({
  isUserAgentMobile = defaultIsMobile,
  children,
}) => {
  const contextValue = useMemo(
    () => ({ isUserAgentMobile }),
    [isUserAgentMobile],
  );

  return (
    <IsMobileContext.Provider value={contextValue}>
      {children}
    </IsMobileContext.Provider>
  );
};
