import React, { Suspense, useMemo } from 'react';
import {
  useAddToFavorites,
  useFavoriteGamesData,
  useRemoveFromFavorites,
} from '@/hooks/games/favorites';
import { useGamesWithFilters } from '@/hooks/games/use-games-with-filters';
import { NewGameCardLoader } from '../Loaders';
import GameCarouselItem from './game-carousel-item';
import CarouselWithGradient from './components/carousel-with-gradient';
import useMediaQuery, { MOBILE_BREAKPOINT } from '@/hooks/use-media-query';
import useDarkMode from '@/hooks/dark';
import { useIsMobile } from '@/modules/home/context/is-mobile';
import { cn } from '@/utils/style';

export const GamesCarouselLoader = ({
  gameCardProps,
}: {
  gameCardProps?: {
    className?: string;
  };
}) => {
  return (
    <div className="flex w-full items-center space-x-2 overflow-hidden">
      {Array.from({ length: 10 }).map((_, i) => (
        <div key={`game-card-loader-${i}`}>
          <NewGameCardLoader
            className={cn(['h-[213px] w-40', gameCardProps?.className])}
          />
        </div>
      ))}
    </div>
  );
};

interface Props {
  identifier: string;
  tagType?:
    | 'primary'
    | 'success'
    | 'warning'
    | 'critical'
    | 'secondary'
    | 'ghost'
    | null
    | string
    | 'transparent';
  tagText?: string;
  options?: any;
}

const GamesCarousel: React.FC<Props> = React.memo(
  ({ tagText, tagType, identifier }) => {
    const { matchesQuery: isMobile, isLoading } =
      useMediaQuery(MOBILE_BREAKPOINT);
    const { darkMode } = useDarkMode();
    const { mutateAsync: addToFavorites } = useAddToFavorites();
    const { mutateAsync: removeFromFavorites } = useRemoveFromFavorites();
    const { favoriteGameIdentifiers } = useFavoriteGamesData();
    const isUserAgentMobile = useIsMobile();

    const { data: games, isLoading: gamesLoading } = useGamesWithFilters(
      {
        device: isUserAgentMobile ? 'mobile' : 'desktop',
        without_territorial_restrictions: true,
        page: 1,
        page_size: 15,
        filter: {
          categories: {
            identifiers: [identifier],
          },
        },
      },
      {
        enabled: identifier && identifier !== '',
      },
    );

    const slides = useMemo(() => {
      const items = games?.pages[0]?.data;
      if (!items) return [];
      return items?.map((game) => {
        return (
          <GameCarouselItem
            key={`game-carouse-${game.id}`}
            game={game}
            favoriteGames={favoriteGameIdentifiers}
            addToFavorites={addToFavorites}
            removeFromFavorites={removeFromFavorites}
            tagText={tagText}
            tagType={tagType}
          />
        );
      });
    }, [
      addToFavorites,
      favoriteGameIdentifiers,
      games?.pages,
      removeFromFavorites,
      tagText,
      tagType,
    ]);

    return gamesLoading ? (
      <div className="pt-3">
        <GamesCarouselLoader />
      </div>
    ) : (
      <Suspense fallback={<GamesCarouselLoader />}>
        <div className="relative">
          <CarouselWithGradient
            withGradient
            gradientFrom={darkMode ? '#0a1c2d' : '#ebeced'}
            itemsWrapperStyles="pt-3 relative"
            space={isMobile ? '8' : '12'}
            items={slides}
            slideWidth={isLoading || isMobile ? 128 : 160}
          />
        </div>
      </Suspense>
    );
  },
);

GamesCarousel.displayName = 'NewGamesCarousel';

export default GamesCarousel;
