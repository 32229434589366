import { cn } from '@/utils/style';
import { Badge } from '@purposeinplay/core-v2';
import React from 'react';

interface Props {
  rtp?: string;
  className?: string;
}
const RtpTag = ({ rtp, className, ...props }: Props) => {
  return null;
  // Uncomment when rtp will be needed

  // return (
  //   <Badge
  //     className={cn([
  //       'absolute right-3 top-3 z-10 overflow-hidden whitespace-nowrap bg-[#5349FC] text-[#ECC94A] max-[330px]:text-[8px]',
  //       className,
  //     ])}
  //     radius="rounded"
  //     type="information"
  //     {...props}
  //   >
  //     {rtp ? <span>RTP {rtp}%</span> : 'RTP N/A'}
  //   </Badge>
  // );
};

export default RtpTag;
