'use client';

import React from 'react';
import Spinner from '../Spinner';
import {
  Heading,
  ListComponent,
  Table,
  TableCell,
  TableRow,
  Text,
} from '@purposeinplay/core-v2';
import { cn } from '@/utils/style';
import Section from '../HomeComponents/components/section';
import { GamesCarouselLoader } from '../Carousels/games-carousel';
import Image from 'next/image';
import useDarkMode from '@/hooks/dark';

// export const GameCardLoader = styled.div`
//   background-size: 200% 100%;
//   ${tw`w-full h-full mb-1.5 rounded-2xl bg-gradient-to-r bg-gray-100 dark:(bg-deepBlueDark98)`}
// `;
export const GameCardLoader = ({ className }: { className?: string }) => (
  <div
    className={cn([
      'mb-1.5 h-full w-full rounded-2xl bg-surface-subdued',
      className,
    ])}
  ></div>
);
// export const GameCardLoaderUpdated = () => (
//   <div className="w-32 md:w-40 rounded-lg overflow-hidden bg-gray-100 dark:(bg-deepBlueDark98)">
//     <div className="aspect-h-4 aspect-w-3"></div>
//   </div>
// );

export const NewGameCardLoader = ({ className }: { className?: string }) => (
  <div
    className={cn(['aspect-[3/4] rounded-2xl bg-surface-subdued', className])}
  ></div>
);

export const BigWinsLoader = () => {
  return (
    <DivLoader className="h-[11.5rem] w-28 rounded-radius-md 2xl:h-[12.5rem]" />
  );
};

export const PinInputLoader = ({ className }: { className?: string }) => {
  return (
    <div className={cn(['flex flex-row gap-2', className])}>
      <DivLoader className="h-10 w-8 rounded-radius-md" />
      <DivLoader className="h-10 w-8 rounded-radius-md" />
      <DivLoader className="h-10 w-8 rounded-radius-md" />
      <DivLoader className="h-10 w-8 rounded-radius-md" />
      <DivLoader className="h-10 w-8 rounded-radius-md" />
      <DivLoader className="h-10 w-8 rounded-radius-md" />
    </div>
  );
};

export const BigWinsCarouselLoader = () => {
  return (
    <div className="flex w-full items-center space-x-2 overflow-hidden">
      {Array.from({ length: 10 }).map((_, i) => (
        <div key={`win-card-loader-${i}`}>
          <BigWinsLoader />
        </div>
      ))}
    </div>
  );
};

// export const NewGameCardLoader = styled.div`
//   background-size: 200% 100%;
//   ${tw`aspect-h-4 aspect-w-3 rounded-2xl bg-gradient-to-r bg-gray-100 dark:(bg-deepBlueDark98)`}
// `;

export const TextLoader = ({
  className,
  as,
}: {
  className?: string;
  as?: React.ElementType;
}) => {
  const Component = as || 'div';
  return (
    <Component
      className={cn([
        'h-4 w-full rounded-lg bg-surface-subdued lg:w-32',
        className,
      ])}
    />
  );
};

export const ButtonLoader = ({ className }: { className?: string }) => {
  return (
    <div
      className={cn(['h-7 w-16 rounded-lg bg-surface-subdued', className])}
    />
  );
};

export const TournamentBannerSubheadingLoader = () => (
  <>
    <TextLoader className="mx-auto mb-2 h-7 lg:w-80" />
    <TextLoader className="mx-auto h-7 lg:w-80" />
  </>
);

export const TournamentLoader = ({ ...props }) => {
  return (
    <div
      className="relative z-50 grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-3"
      {...props}
    >
      <div className="h-[351px] !w-full rounded-xl bg-surface-subdued lg:h-[446px] dark:bg-surface-default" />
      <div className="h-[351px] !w-full rounded-xl bg-surface-subdued lg:h-[446px] dark:bg-surface-default" />
      <div className="h-[351px] !w-full rounded-xl bg-surface-subdued lg:h-[446px] dark:bg-surface-default" />
    </div>
  );
};

// export const QRLoader = styled.div<{ disableDark?: boolean }>`
//   background-size: 200% 100%;
//   ${tw`w-[120px] h-[120px] rounded-2xl bg-gradient-to-r bg-gray-100`}
//   ${({ disableDark = false }) => !disableDark && tw`dark:(bg-deepBlueDark98)`}
// `;

// const ProviderLoader = styled.div<{ disableDark?: boolean }>`
//   background-size: 200% 100%;
//   ${tw`w-[10.75rem] h-[5.813rem] md:(h-[6.313rem] w-[13.313rem]) rounded-lg bg-gradient-to-r bg-gray-100`}
//   ${({ disableDark = false }) => !disableDark && tw`dark:(bg-deepBlueDark98)`}
// `;

// export const PromotionLoader = styled.div<{ disableDark?: boolean }>`
//   background-size: 200% 100%;
//   ${tw`w-full h-56 rounded-lg bg-gradient-to-r bg-gray-100`}
//   ${({ disableDark = false }) => !disableDark && tw`dark:(bg-deepBlueDark98)`}
// `;

// export const WalletLoader = styled.div<{ disableDark?: boolean }>`
//   background-size: 200% 100%;
//   ${tw`w-full h-20 rounded-lg bg-gradient-to-r bg-gray-100`}
//   ${({ disableDark = false }) => !disableDark && tw`dark:(bg-deepBlueDark98)`}
// `;

// const DivLoader = styled.div<{ disableDark?: boolean }>`
//   background-size: 200% 100%;
//   ${tw`bg-gradient-to-r bg-gray-100`}
//   ${({ disableDark = false }) => !disableDark && tw`dark:(bg-deepBlueDark98)`}
// `;

export const DivLoader = ({ className }: { className?: string }) => {
  return (
    <div
      className={cn([
        'h-4 w-full rounded-lg bg-surface-subdued lg:w-32 dark:bg-surface-default',
        className,
      ])}
    />
  );
};

export const ProvidersLoader = () => {
  return (
    <div className="grid grid-cols-2 gap-4 sm:grid-cols-3 md:grid-cols-4">
      {Array.from(Array(16).keys()).map((key) => (
        <DivLoader
          key={`games-scheleton-${key}`}
          className="min-h-[6.25rem] w-full md:w-full"
        />
      ))}
    </div>
  );
};

export const GamesScheletonLoader = () => {
  return (
    <div className="mb-5 grid grid-cols-3 gap-2  last:mb-0 sm:grid-cols-5 lg:grid-cols-8">
      {Array.from(Array(16).keys()).map((key) => (
        <NewGameCardLoader key={`games-scheleton-${key}`} />
      ))}
    </div>
  );
};

export const GamesRowScheletonLoader = () => {
  return (
    <div className="mb-5 grid grid-cols-3 gap-2 last:mb-0 sm:grid-cols-5 lg:grid-cols-8">
      {Array.from({ length: 7 }).map((_, i) => (
        <NewGameCardLoader key={i} />
      ))}
    </div>
  );
};

export const ParagraphLoader = () => {
  return (
    <div className="flex flex-col space-y-4">
      <TextLoader className="h-6 w-[80%] lg:w-[80%]" />
      {Array.from({ length: 5 }).map((_, i) => (
        <TextLoader
          key={i}
          className={`h-4 !w-[${(i + 1) * 10}%] lg:w-[${(i + 1) * 10}%]`}
        />
      ))}
    </div>
  );
};

export const CollectionLoader = () => {
  return (
    <div className="grid grid-cols-6">
      {/* @ts-ignore */}
      {[...Array(6).keys()].map((el) => (
        <div key={`game-card-loader-${el}`}>
          <NewGameCardLoader />
        </div>
      ))}
    </div>
  );
};

export const WheelCounterLoader = ({ ...props }) => {
  return (
    <div tw="w-full">
      <TextLoader className="mx-auto mb-1 h-6 md:mb-3 lg:w-24" />
      <TextLoader className="mx-auto h-12 !w-1/2" />
    </div>
  );
};

// export const ProvidersLoader = () => {
//   return (
//     <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
//       {Array.from(Array(16).keys()).map((key) => (
//         <ProviderLoader key={`games-scheleton-${key}`} className="mr-0" />
//       ))}
//     </div>
//   );
// };

// export const PromotionsLoader = () => {
//   return (
//     <div>
//       <TextLoader className="rounded-2xl overflow-hidden relative mb-4 px-4 py-7 w-full lg:w-full h-[22.25rem]" />
//       <div className="grid grid-cols-1 gap-2 sm:grid-cols-2 lg:grid-cols-3 lg:gap-4">
//         {Array.from(Array(3).keys()).map((key) => (
//           <div key={`games-scheleton-${key}`}>
//             <PromotionLoader className="mb-4 h-44 w-full" />
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

export const TabsLoader = ({ ...props }) => {
  return (
    <div className="xl:(grid-cols-8) grid grid-cols-4 gap-8" {...props}>
      {Array.from(Array(4).keys()).map((key) => (
        <TextLoader
          key={`tabs-scheleton-${key}`}
          className="mr-0 h-7 rounded-full"
        />
      ))}
    </div>
  );
};

export const TableLoader = () => {
  return (
    <div>
      <div className="mb-4 grid grid-cols-4 justify-between gap-2 lg:flex">
        <TextLoader className="h-4" />
        <TextLoader className="h-4" />
        <TextLoader className="h-4" />
        <TextLoader className="h-4" />
      </div>
      <div>
        <TextLoader className="mb-1 h-11 w-full rounded-radius-md lg:w-full" />
        <TextLoader className="mb-1 h-11 w-full rounded-radius-md opacity-50 lg:w-full" />
        <TextLoader className="mb-1 h-11 w-full rounded-radius-md lg:w-full" />
        <TextLoader className="mb-1 h-11 w-full rounded-radius-md opacity-50 lg:w-full" />
        <TextLoader className="h-11 w-full rounded-radius-md lg:w-full" />
      </div>
    </div>
  );
};

export const FullpageLoader = ({
  title = 'Loading...',
  subTitle,
  ...props
}: {
  title?: string;
  subTitle?: string;
}) => {
  return (
    <div
      className="ml-6 mr-3 flex min-h-[19.25rem] items-center justify-center xl:mx-auto"
      {...props}
    >
      <div className="flex flex-col items-center">
        <Spinner className="mb-1 h-6 w-6" />
        <Text size="base" className="mb-1 text-text-default">
          {title}
        </Text>
        {subTitle && (
          <Text size="sm" className="text-text-default">
            {subTitle}
          </Text>
        )}
      </div>
    </div>
  );
};

const TransactionsLoader = ({ ...props }) => {
  return (
    <div className="lg:(grid-cols-2 gap-y-10) grid gap-4 gap-y-10" {...props}>
      <div className="lg:(col-span-2)">
        <TableLoader />
      </div>
    </div>
  );
};

const TransactionsLoaderMobile = ({ ...props }) => {
  return (
    <div
      className="flex cursor-pointer flex-col justify-between border-b border-surface-subdued pb-4"
      {...props}
    >
      <div className="mb-2 flex items-center">
        <TextLoader className="mr-4 h-8 w-8" />
        <TextLoader className="h-6 w-28" />
        <TextLoader className="ml-auto h-6 w-28" />
      </div>
      <div className="flex justify-between">
        <TextLoader className="h-6 w-12" />
        <TextLoader className="h-6 w-12" />
      </div>
    </div>
  );
};

// export const TournamentLoader = ({ ...props }) => {
//   return (
//     <div className="grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-3" {...props}>
//       <GameCardLoader className="h-80 w-[24.5rem]" />
//       <GameCardLoader className="h-80 w-[24.5rem]" />
//       <GameCardLoader className="h-80 w-[24.5rem]" />
//     </div>
//   );
// };

// export const WalletCardsLoader = () => {
//   return (
//     <div className="grid grid-cols-1 lg:grid-cols-3 gap-2 w-full">
//       <WalletLoader className="h-16 w-full" />
//       <WalletLoader className="h-16 w-full" />
//       <WalletLoader className="h-16 w-full" />
//     </div>
//   );
// };

export const HeroSlideLoader = ({ ...props }) => {
  return (
    <div className="mb-3 md:mb-7" {...props}>
      <TextLoader className="relative flex h-[12rem] min-w-[22.5rem] overflow-hidden rounded-3xl" />
    </div>
  );
};

export const AvatarLoader = ({
  className,
  ...props
}: {
  className?: string;
}) => {
  return (
    <TextLoader
      className={cn(['h-10 w-10 rounded-full lg:w-10', className])}
      {...props}
    />
  );
};

// export const WheelCounterLoader = ({ ...props }) => {
//   return (
//     <div className="w-full ">
//       <TextLoader className="h-7 mb-7 !w-full" />
//       <TextLoader className="h-7 mb-2 md:mb-3 !w-full" />
//       <TextLoader className="h-14 mb-7 !w-1/2 mx-auto" />
//     </div>
//   );
// };

// export const WithdrawLoader = ({ ...props }) => {
//   return (
//     <div className="flex flex-col" {...props}>
//       <TextLoader className="h-18 mb-4 md:mb-7 md:w-full" />
//       <TextLoader className="h-18 mb-2 md:mb-7 md:w-full" />
//       <TextLoader className="h-18  mb-2 md:mb-7 md:w-full" />
//       <TextLoader className="h-[40.75px]  mb-2 md:mb-7 md:w-full" />
//       <ButtonLoader className="mb-1 lg:mb-4" />
//     </div>
//   );
// };

// export const DepositLoader = ({ optionalData, ...props }) => {
//   return (
//     <>
//       <DivLoader className="rounded-2xl mt-0 w-full h-[6.25rem] md:h-20 mb-2" />
//       {optionalData && optionalData.fieldName === "tag" && (
//         <DivLoader className="rounded-2xl mt-0 w-full h-[6.25rem] md:h-20 mb-2" />
//       )}
//       <div className="flex flex-col space-y-2 md:(flex-row space-y-0 items-center space-x-2 )">
//         <TextLoader className="h-6 rounded-full w-2/3" />
//         <TextLoader className="h-6 rounded-full w-1/3" />
//       </div>
//     </>
//   );
// };

export const InputLoader = ({ className }: { className?: string }) => (
  <div className={cn(['flex flex-col', className])}>
    <TextLoader className="mb-1 h-7 lg:w-10" />
    <TextLoader className="mb-[1px] h-10 border border-transparent lg:w-full" />
  </div>
);

// export const PaymentMethodLoader = ({ ...props }) => {
//   return (
//     <>
//       <div {...props}>
//         <div className="lg:(col-span-2)">
//           <InputLoader className="mb-4 w-full lg:w-full" />
//           <InputLoader className="mb-4 w-full lg:w-full" />
//           <ButtonLoader />
//         </div>
//       </div>
//     </>
//   );
// };

export const TransactionLoaders = () => {
  return (
    <>
      <TransactionsLoader className="md:(block rounded-lg) hidden" />
      <TransactionsLoaderMobile className="md:hidden" />
    </>
  );
};

export const ChallengeCardLoader = ({ key }: { key: any }) => {
  return (
    <div className="pt-2">
      <div
        className="relative flex flex-col overflow-hidden  rounded-xl bg-surface-hovered p-2"
        key={key}
      >
        <div className="mb-2 aspect-[3/4] rounded-lg bg-surface-selected-default"></div>
        <div className="flex flex-col items-center text-sm leading-4">
          <div className="mb-3 h-8 font-medium">
            <span className="flex flex-col space-y-2 text-text-default">
              <TextLoader className="w-32  !bg-surface-selected-default" />
              <TextLoader className="w-32  !bg-surface-selected-default" />
            </span>
          </div>
          <div className="flex items-center justify-between">
            <div className="flex flex-col space-y-2">
              <TextLoader className="w-32  !bg-surface-selected-default" />
              <TextLoader className="w-32  !bg-surface-selected-default" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ProviderListLoader = () =>
  Array.from({ length: 12 }).map((_, i) => (
    <TextLoader
      key={`provider-loader-${i}`}
      className="h-[40px] w-[101px]  md:h-[44px] md:w-[133px]"
    />
  ));

export const PopularGamesLoader = () => (
  <div>
    <TextLoader className="mb-1 h-7" />
    {Array.from({ length: 3 }).map((_, index) => (
      <div
        key={index}
        className="
'relative hover:bg-surface-hovered', wild-transition flex flex-row items-center justify-between space-x-2 rounded-lg p-2
"
      >
        <div className="flex flex-row items-center space-x-2">
          <div className="relative h-8 w-8 rounded-lg bg-surface-default [&>div]:first-of-type:rounded-lg"></div>
          <div className="flex flex-col">
            <TextLoader className="mb-0.5 font-medium leading-5" />
            <TextLoader className="font-normal capitalize leading-none text-text-subdued" />
          </div>
        </div>
      </div>
    ))}
  </div>
);
export const SearchableSelectLoader = () => {
  return (
    <div className="flex h-16 w-full flex-col justify-between">
      <TextLoader className="mb-0.5 h-4 w-20" />
      <TextLoader className="h-10 lg:w-full" />
    </div>
  );
};

export const BonusDataLoader = () => (
  <div className="flex h-[82px] flex-col space-y-2">
    <TextLoader className="h-[58px] lg:w-full" />
    <TextLoader className="h-4" />
  </div>
);

export const TableLoaderV2 = ({
  headers,
  tableRowProps,
}: {
  headers: number;
  tableRowProps?: string;
}) => {
  return (
    <Table
      stylingOptions={{
        containerStyles: 'p-0',
      }}
      headers={Array.from({ length: headers }).map((_, i) => (
        <TextLoader key={i} />
      ))}
    >
      {[...Array(3)].map((_, rowIndex) => (
        <TableRow key={rowIndex} className={tableRowProps}>
          {Array.from({ length: headers }).map((_, cellIndex) => (
            <TableCell key={cellIndex}>
              {' '}
              <TextLoader />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </Table>
  );
};

export const InputWithLabelLoader = () => (
  <div className="flex flex-col">
    <TextLoader className="mb-[9px] h-6 w-16 lg:w-16" />
    <TextLoader className="h-10 lg:w-auto" />
  </div>
);

export const WithdrawLoader = () => (
  <div className="flex flex-col space-y-7">
    <div className="flex flex-col space-y-4">
      <InputWithLabelLoader />
      <div>
        <TextLoader className="mb-1 w-16 lg:w-16" />
        <TextLoader className="h-5 lg:w-auto" />
      </div>
      <InputWithLabelLoader />
      <InputWithLabelLoader />
    </div>
    <TextLoader className="h-[18px] w-full lg:w-full" />
    <TextLoader className="h-10 lg:w-full" />
  </div>
);

export const MobileGameDetailsLoader: React.FC<{ className?: string }> = ({
  className,
}) => (
  <div
    className={cn([
      'relative z-0 flex flex-col rounded-2xl bg-bgr-subtile p-3',
      className,
    ])}
  >
    <div className="mb-7 grid grid-cols-6 gap-4 sm:grid-cols-10">
      <div className="relative col-span-3 h-56 overflow-hidden rounded-xl sm:col-span-3">
        <TextLoader className="h-full lg:w-auto" />
      </div>
      <div className="col-span-3 flex flex-col justify-center sm:col-span-7">
        <TextLoader className="h-16" />
        <TextLoader className="mb-3 h-7" />
        <div className="flex flex-row items-center space-x-1">
          <TextLoader />
        </div>
      </div>
    </div>
    <TextLoader className="mb-3 h-10 w-full" />
    <div className="flex justify-between space-x-2">
      <div className="relative z-[0] flex w-full justify-between space-x-2">
        <TextLoader className="h-10" />
        <TextLoader className="h-10" />
      </div>
    </div>
  </div>
);

export const ChallengeListLoader = ({
  forPage,
}: {
  forPage: boolean | undefined;
}) => {
  return (
    <div
      className={cn([
        'grid w-full grid-cols-1  gap-2 md:grid-cols-2',
        forPage &&
          'grid grid-cols-1 gap-2 sm:grid-cols-2 sm:gap-4 lg:grid-cols-2 xl:grid-cols-3 ',
      ])}
    >
      {Array.from({ length: 9 }).map((_, index) => {
        return (
          <div key={index}>
            <div>
              <div className="wild-transition flex flex-row space-x-2.5 rounded-3xl bg-surface-default p-2 group-hover:-translate-y-1">
                <div>
                  <div className="relative h-[96px] w-[96px] shrink-0 overflow-hidden rounded-3xl">
                    <TextLoader className="h-full w-full lg:w-full" />
                  </div>
                </div>
                <div className="flex-1">
                  <div className="flex h-full max-w-[17rem] flex-1 flex-col justify-between text-sm leading-4">
                    <div>
                      <TextLoader className="mb-1 w-32" />

                      <TextLoader className="mb-1 w-32" />
                      <TextLoader className="w-32 " />
                    </div>

                    <TextLoader className="w-32" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export const RightNavLoader = () => {
  return (
    <div className="flex flex-1 flex-row items-center justify-end space-x-2">
      <DivLoader className="h-10 w-[84px] lg:w-[84px]" />
      <DivLoader className="h-10 w-[84px] lg:w-[84px]" />
    </div>
  );
};

export const DepositLoader = () => (
  <div className="flex flex-col items-center justify-center lg:pt-4">
    <div className="mb-3">
      <div className="flex items-center justify-center">
        <TextLoader className="h-[124px] w-[124px] lg:w-[124px]" />
      </div>
      <TextLoader className="h-7" />
    </div>
    <div className="w-full">
      <TextLoader className="mb-1 h-6" />
      <TextLoader className="mb-3 h-10" />
      <TextLoader className="h-7" />
    </div>
  </div>
);

export const ProviderCarouselLoader = () => {
  return (
    <div className="flex flex-row space-x-2.5 overflow-hidden">
      {Array.from({ length: 10 }).map((item, i) => (
        <div
          key={i}
          className="h-[100px] w-[180px] shrink-0 rounded-xl bg-surface-subdued"
        />
      ))}
    </div>
  );
};

export const GameSectionLoader = () => {
  return (
    <Section>
      <TextLoader className="mb-3 h-8 rounded-full" />
      <GamesCarouselLoader />
    </Section>
  );
};

export const GameSectionLoaders = () => (
  <>
    <GameSectionLoader />
    <GameSectionLoader />
  </>
);

export const LotteryCardLoader = () => {
  return (
    <div className="relative h-[23.125rem] w-[16.875rem] shrink-0 overflow-hidden rounded-2xl bg-surface-default">
      <div className="absolute inset-0 z-10 flex flex-col items-center justify-between p-3 !pb-7">
        <div className="flex w-full flex-col items-center">
          <div
            className={cn([
              'relative flex w-full items-center justify-between',
            ])}
          >
            <div className="flex flex-row">
              <TextLoader className="h-7 w-7 lg:h-7 lg:w-7" />
            </div>
            <TextLoader className="lg:-w-4 h-4 w-4 lg:h-4" />
          </div>
          <TextLoader className="mx-auto mb-2 h-16 w-16 lg:h-16 lg:w-16" />
          <TextLoader />

          <TextLoader className="mb-9" />
          <TextLoader className="h-[72px]" />
        </div>
        <TextLoader className="mx-auto h-10" />
      </div>
    </div>
  );
};

export const FsLoader = () => {
  const { darkMode } = useDarkMode();
  return (
    <div className="relative flex flex-col items-center justify-center overflow-hidden rounded-2xl bg-[#FCFCFC] p-3 pt-4 md:min-w-[267px] dark:bg-[#263747]">
      <Image
        className="pointer-events-none"
        src={`/assets/bonus-shop/card-background/fs${darkMode ? '-dark' : ''}.png`}
        fill
        alt="Card background loader"
        style={{ objectFit: 'cover' }}
      />
      <div className="relative z-10 flex w-full flex-col items-center text-center">
        <Image
          className="mb-1"
          src={`/assets/bonus-shop/fs-icon.svg`}
          width={96}
          height={96}
          alt="Free spins icon loader"
        />
        <TextLoader className="mb-4 flex h-[28px] w-32 flex-row items-center space-x-1 text-xl font-bold"></TextLoader>
        <div className="mb-3 flex w-full flex-col space-y-0.5">
          {[1, 2, 3].map((_, i) => (
            <ListComponent
              key={i}
              stylingOptions={{
                containerStyles: cn([
                  '!w-full bg-surface-subdued h-8 hover:bg-surface-subdued ',
                ]),
                valueStyles:
                  '!py-[9px] text-right whitespace-nowrap  flex justify-end',
                labelStyles: '!py-[9px] whitespace-nowrap',
              }}
              value={<TextLoader />}
              label={<TextLoader />}
            />
          ))}
        </div>

        <TextLoader className="h-8 w-full lg:w-full" />
      </div>
    </div>
  );
};

export const LoyaltyHeaderLoader = () => (
  <div className="order-2 flex w-full max-w-[43.75rem] flex-col space-y-1 lg:order-1">
    <TextLoader className="h-12 w-full lg:w-4/5" as="span" />
    <TextLoader className="h-12 w-full  lg:w-full" as="span" />
    <TextLoader className="h-[30px] w-full lg:h-10 lg:w-2/3" as="span" />
    <TextLoader className="h-[30px] w-full  lg:h-10 lg:w-1/2" as="span" />
  </div>
);

export const ChatBubbleLoader = () => (
  <div className={cn(['mb-3 flex flex-row space-x-2'])}>
    <div className="h-[30px] w-[30px] shrink-0 rounded-full bg-green-300"></div>

    <div className="flex w-3/4 flex-col space-y-1">
      <div className={cn(['flex h-6 flex-row items-center space-x-3'])}>
        <TextLoader />
        <TextLoader />
      </div>
      <div
        className={cn([
          'flex h-11 items-center  rounded-lg bg-bgr-lighter p-3 text-sm text-text-subdued',
        ])}
      >
        <TextLoader />
      </div>
    </div>
  </div>
);

export const TableLoaderJackpot = () => {
  return (
    <div>
      <div className="mb-1 flex flex-row justify-between">
        <TextLoader className="h-8 w-11" />
        <TextLoader className="h-8 w-11" />
      </div>
      <div className="flex w-full flex-col gap-1">
        <TextLoader className="h-10 w-full rounded-radius-md lg:w-full" />
        <TextLoader className="h-10 w-full rounded-radius-md lg:w-full" />
        <TextLoader className="h-10 w-full rounded-radius-md lg:w-full" />
        <TextLoader className="h-10 w-full rounded-radius-md lg:w-full" />
      </div>
    </div>
  );
};
