import React from 'react';
import { IconButton } from '@purposeinplay/core-v2';
import HeartSVG from 'public/assets/heart-full.svg';
import { cn } from '@/utils/style';

interface Props {
  isFavorited?: boolean;
  onFavoriteClick?: (evt: React.MouseEvent<HTMLButtonElement>) => void;
  className?: string;
  size?: 'xs' | 'sm';
  isSimpleVariant?: boolean;
  isDisabled?: boolean;
}

const FavoriteButton = ({
  isSimpleVariant = false,
  isFavorited = false,
  onFavoriteClick,
  className,
  size = 'sm',
  isDisabled = false,
}: Props) => {
  if (isSimpleVariant) {
    return (
      <span
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
          e.preventDefault();
          if (!isDisabled && onFavoriteClick) {
            onFavoriteClick(e);
          }
        }}
        className={cn([
          'flex h-6 w-6 items-center justify-center',
          isDisabled && 'cursor-not-allowed opacity-80',
        ])}
      >
        <HeartSVG
          className={cn([
            isFavorited
              ? 'fill-current text-red-500'
              : 'fill-current text-text-default',
          ])}
        />
      </span>
    );
  }
  return (
    <IconButton
      iconOnly
      color="tertiary"
      onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        if (!isDisabled && onFavoriteClick) {
          onFavoriteClick(e);
        }
      }}
      disabled={isDisabled}
      size={size}
      iconRight={
        <HeartSVG
          className={cn([isFavorited && 'fill-current text-red-500'])}
        />
      }
      className={cn([
        'absolute right-2 top-2 flex items-center justify-center !rounded-full text-text-on-primary [&>span]:!h-5 [&>span]:!w-5',
        className,
      ])}
    />
  );
};

export default FavoriteButton;
