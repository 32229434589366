import { useQuery, UseQueryResult } from '@tanstack/react-query';
import client from '@/utils/api';

const useMarks = (): UseQueryResult<string[]> => {
  return useQuery({
    queryKey: ['marks'],
    queryFn: () => client('restrictions/marks'),
    retry: false,
    refetchOnMount: false,
  });
};

export default useMarks;
