import React, { FC, useMemo, useState } from 'react';
import { useEffect } from 'react';
import RtpTag from './rtp-tag';
import { cn } from '@/utils/style';
import './gamecard.css';
import GameCardOverlay from './overlay';
import useIsRestricted from '@/hooks/games/use-is-restricted';
import useMediaQuery, { MOBILE_BREAKPOINT } from '@/hooks/use-media-query';
import { GameCardLoader } from '../Loaders';
import Image from 'next/image';
import GameCardImage from './game-card-image';
import { isJackpot } from '../Games/utils';

interface Props {
  /** The image component*/
  image?: React.ReactNode;
  /** If no image component has been passed, an imageUrl can be used*/
  imageUrl?: string;
  /** Fallback image url*/
  fallBackUrl?: string;
  /** Low quality image for lazy*/
  thumbnail?: string;
  /** The title displayed under the card */
  title?: string;
  /** Displays info content in a popover when hovering the info icon*/
  info?: JSX.Element | Element;
  /** The play icon displayed in the center of the thumbnail*/
  playIcon?: any;
  onClick?(evt: React.MouseEvent<HTMLDivElement>): void;
  /** If true, the favorite button will be hidden */
  hideFavoriteButton?: boolean;
  /** Fires when the favorite button is clicked */
  onFavoriteClick?(evt: React.MouseEvent<HTMLButtonElement>): void;
  /** If true, the favorite button will be highlighted.*/
  isFavorited?: boolean;
  /** Different styles for category page.*/
  isForCategory?: boolean;
  /** If true, the game card will have a specific size because the carousel needs the cards to have a width */
  carouselDisplayed?: boolean;
  /** The alt for the image.*/
  alt?: string;
  /** The tag for the RTP*/
  tag?: string;
  /** The game provider*/
  provider: string;
  /** The game identifier*/
  identifier?: string;
  isInMiniCarousel?: boolean;
  /** If true, the favorite button will be disabled */
  isDisabled?: boolean;
}

export const GameCard: FC<Props> = ({
  image,
  imageUrl,
  title,
  tag,
  provider,
  onClick,
  playIcon,
  fallBackUrl,
  carouselDisplayed = false,
  hideFavoriteButton = false,
  onFavoriteClick,
  isFavorited,
  identifier,
  isInMiniCarousel,
  isDisabled,
}): JSX.Element => {
  const [isHovered, setIsHovered] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  const { matchesQuery: isMobile } = useMediaQuery(MOBILE_BREAKPOINT);

  useEffect(() => {
    const timeoutId: any =
      isHovered && setTimeout(() => setShowOverlay(true), 300);

    return () => clearTimeout(timeoutId);
  }, [isHovered]);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => {
    setIsHovered(false);
    setShowOverlay(false);
  };
  const [src, setSrc] = React.useState(imageUrl);

  const { isRestricted, isPending } = useIsRestricted(provider);
  const isGameJackpot = isJackpot(identifier);
  const isRestrictedUpdated = isGameJackpot || isRestricted;

  return (
    <div
      data-testid="game-card"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={onClick}
      className={cn([
        `relative flex
      flex-col
      rounded-2xl
      border-none`,
      ])}
    >
      <div
        className={cn([
          'image-wrapper relative aspect-[3/4]  overflow-hidden transition duration-200 ease-in-out',
          !isMobile && isHovered && `-translate-y-2`,
          carouselDisplayed && 'w-32 md:w-40',
          isInMiniCarousel && 'w-[109px] md:w-[109px]',
        ])}
      >
        {isRestrictedUpdated && (
          <div className="pointer-events-none absolute inset-0 z-10 bg-gray-800/50"></div>
        )}
        {showOverlay && (
          <GameCardOverlay
            isJackpot={isGameJackpot}
            provider={provider}
            show={showOverlay}
            title={title}
            playIcon={playIcon}
            hideFavoriteButton={hideFavoriteButton}
            isFavorited={isFavorited}
            onFavoriteClick={onFavoriteClick}
            isDisabled={isDisabled}
          />
        )}
        <GameCardImage
          src={src}
          isPending={isPending}
          setSrc={setSrc}
          onError={() => setSrc(fallBackUrl ?? '')}
          imageUrl={imageUrl}
          image={image}
          title={title}
          fallBackUrl={fallBackUrl}
        />
        <RtpTag rtp={tag} />
      </div>
    </div>
  );
};
