import { Collection, GameProvider } from '@/types/games';
import { CATEGORY_MAPPING, LANDING_COLLECTIONS } from '../constants';
import { QueryClient } from '@tanstack/react-query';
import client, { prefetchQuery } from '@/utils/api';
import { REMOVED_CATEGORIES } from '@/hooks/collections';
import { prefetchInfiniteFilteredGames } from '@/hooks/games/filters';
import config from '@/config';

export const formatProviders = (providers: GameProvider[]) => {
  return (
    providers?.map((provider) => ({
      id: provider.id,
      value: provider.title,
    })) || []
  );
};

export const formatCollections = (collections: Collection[]) => {
  return (
    collections
      ?.map((collection) => ({
        id: collection.id,
        value: collection.title,
      }))
      .sort((a, b) => a.value.localeCompare(b.value)) || []
  );
};

export const getFilterTitle = (title: string, selected: any[]) => {
  const isSelectedArray = Array.isArray(selected);
  if (selected?.length === 0 || !selected) {
    return `${title}: All`;
  }
  return `${title}: ${isSelectedArray ? selected.length : '1'}`;
};

export const getCategoryFilters = (
  isUserAgentMobile: boolean,
  collection?: string,
  pageParam?: number,
) => {
  const platform = isUserAgentMobile ? 'mobile' : 'desktop';
  return {
    without_territorial_restrictions: true,
    sort: { direction: 'ASC', type: 'novelty' },
    device: platform,
    page: pageParam || '1',
    page_size: isUserAgentMobile ? 9 : 32,
    filter: {
      providers: [],
      categories: {
        identifiers: collection ? [collection] : [],
        strategy: 'AND',
      },
    },
  };
};

export const getGameFilters = (
  isUserAgentMobile: boolean,
  pageParam?: number | undefined,
) => {
  return {
    without_territorial_restrictions: true,
    page: pageParam || 1,
    page_size: isUserAgentMobile ? 9 : 32,
    filter: {
      providers: [],
    },
    sort: {
      type: 'novelty',
      direction: 'ASC',
    },
    device: isUserAgentMobile ? 'mobile' : 'desktop',
  };
};

export const getCollectionGameFilters = (
  isUserAgentMobile: boolean,
  name: string,
  pageParam?: number | undefined,
) => {
  return {
    without_territorial_restrictions: true,
    page: pageParam || 1,
    page_size: isUserAgentMobile ? 9 : 32,
    filter: {
      categories: {
        identifiers: [name],
      },
    },
    sort: {
      type: 'novelty',
      direction: 'ASC',
    },
    device: isUserAgentMobile ? 'mobile' : 'desktop',
  };
};

export const getGameCarouselFilters = (
  isUserAgentMobile: boolean,
  identifier: string,
) => {
  return {
    without_territorial_restrictions: true,
    page_size: 15,
    filter: {
      categories: {
        identifiers: [identifier],
      },
    },
    device: isUserAgentMobile ? 'mobile' : 'desktop',
  };
};

export const getCategoryParam = (param: string) =>
  CATEGORY_MAPPING[param] ?? param;

// Function to fetch and filter all wild categories
export const fetchCollectionsData = (queryClient: QueryClient) => {
  return prefetchQuery('all-wild-categories', queryClient, () =>
    client('games/collections').then((data = []) => {
      return data.filter(
        (collection: { id: string }) =>
          !REMOVED_CATEGORIES.includes(collection.id),
      );
    }),
  );
};

// Function to fetch and filter wild categories
export const fetchWildCategories = (queryClient: QueryClient) => {
  return prefetchQuery('wild-categories', queryClient, () =>
    client('games/collections').then((data = []) => {
      return data?.filter((collection: any) =>
        config.SHOWN_WILD_CATEGORIES.includes(collection.id),
      );
    }),
  );
};

// Function to fetch providers data
export const fetchProviderData = (queryClient: QueryClient) => {
  return prefetchQuery('providers', queryClient, () =>
    client('games/providers'),
  );
};

// Function to prefetch infinite games based on landing collections
export const prefetchLandingCollections = (
  queryClient: QueryClient,
  isUserAgentMobile: boolean,
) => {
  return Promise.all(
    LANDING_COLLECTIONS.map((collection) =>
      prefetchInfiniteFilteredGames({
        queryClient,
        isUserAgentMobile,
        filterType: collection,
      }),
    ),
  );
};

export const getProviderPageFilter = (
  isUserAgentMobile: boolean,
  pageParam: string | string[] | undefined,
  provider: string,
) => {
  return {
    without_territorial_restrictions: true,
    device: isUserAgentMobile ? 'mobile' : 'desktop',
    page: pageParam ? Number(pageParam) : 1,
    page_size: isUserAgentMobile ? 9 : 32,
    filter: {
      providers: [provider],
      categories: {
        identifiers: ['all'],
      },
    },
    sort: {
      type: 'novelty',
      direction: 'ASC',
    },
  };
};

export const getBackText = (collection: string) => {
  switch (collection) {
    case 'new':
      return 'New';
    case 'fruit-games':
      return 'Fruits';
    case 'crash-games':
      return 'Crash Games';
    case 'megaways':
      return 'Megaways';
    case 'low-volatility':
      return 'Low Volatility';
    case 'high-volatility':
      return 'High Volatlilty';
    case 'hold-and-win':
      return 'Hold & Win';
    case 'egyptian':
      return 'Egyptian';
    case 'asian-games':
      return 'Asian';
    case 'provably-fair':
      return 'Provably Fair';
    case 'easter-games':
      return 'Easter Games';
    case 'st-patrick-games':
      return "St Patrick's";
    case 'valentine-games':
      return "Valentine's Day";
    case 'fishing':
      return 'Fishing';
    case 'sport-games':
      return 'Sport';
    case 'music':
      return 'Music';
    case 'video-poker':
      return 'Video Poker';
    default:
      return collection;
  }
};
