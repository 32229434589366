import { useTranslation } from '@/app/i18n/client';
import { GeneralContentType } from '@/types/json-content/general';
import Image from 'next/image';
import React from 'react';

const RestrictedGamecardOverlay = () => {
  const { t } = useTranslation();
  const generalContent = t('general') as GeneralContentType;
  const restrictedPage = generalContent?.restricted_page;
  return (
    <div className="absolute inset-0 flex flex-col items-center justify-center p-1">
      <div className="mb-1 text-center font-bold text-white">
        {restrictedPage?.gamecard?.title}
      </div>
      <div className="text-center text-xs font-medium text-[#ADBDCC]">
        {restrictedPage?.gamecard?.description}
      </div>
    </div>
  );
};

export default RestrictedGamecardOverlay;
