import { useAuth } from './auth';

const useShouldFillProfile = (provider?: string) => {
  const {
    user: { currentUser },
  } = useAuth();
  if (!provider || provider !== 'evolution') return false;
  return (
    (currentUser?.first_name &&
      currentUser?.last_name &&
      currentUser?.country) === null
  );
};

export default useShouldFillProfile;
