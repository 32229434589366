import React from 'react';
import { EmblaCarousel } from '@purposeinplay/core-v2';
import { type EmblaOptionsType as CarouselOptions } from 'embla-carousel';

const CarouselWithGradient = ({
  items,
  space = '8',
  itemsWrapperStyles,
  containerStyles,
  withPagination = false,
  rightBtnStyles,
  leftBtnStyles,
  showArrows,
  paginationStyles,
  withGradient,
  slideWidth,
  gradientFrom,
  options,
}: {
  items: React.ReactNode[];
  space?: string;
  itemsWrapperStyles?: string;
  containerStyles?: string;
  showArrows?: boolean;
  rightBtnStyles?: string;
  leftBtnStyles?: string;
  horizontalEdgeGap?: boolean;
  slideWidth: number | 'full';
  withPagination?: boolean;
  paginationStyles?: string;
  withGradient?: boolean;
  gradientFrom?: string;
  options?: CarouselOptions;
}) => {
  return (
    <EmblaCarousel
      options={options}
      gradientFrom={gradientFrom}
      withGradient={withGradient}
      showArrows={showArrows}
      slideWidth={slideWidth}
      stylingOptions={{
        containerStyles: containerStyles,
        itemsWrapperStyles: itemsWrapperStyles,
        ogLeftButtonStyles: leftBtnStyles,
        ogRightButtonStyles: rightBtnStyles,
        paginationStyles: paginationStyles,
      }}
      space={space}
      withPagination={withPagination}
      items={items}
    />
  );
};

export default CarouselWithGradient;
